import { useEffect, useState } from "react";

import KButton from "@kikoff/components/src/v1/buttons/KButton";
import { cls } from "@kikoff/utils/src/string";

import styles from "./country_redirect_header.module.scss";

export const detectCountry = async () => {
  const response = await fetch("/api/region");
  if (response.ok) {
    const data = await response.json();
    return data.country;
  }
  return "NONE";
};

interface CountryRedirectHeader {
  className?: string;
}

export default function CountryRedirectHeader({
  className,
}: CountryRedirectHeader) {
  const [isCanada, setIsCanada] = useState(false);
  useEffect(() => {
    detectCountry().then((country) => {
      setIsCanada(country === "CA");
    });
  }, []);

  if (!isCanada) return <div />;

  return (
    <div className="pb-2">
      {isCanada && (
        <div
          className={cls(
            styles["bg"],
            "w-full py-2 pl-2 center-child",
            className
          )}
        >
          <div className={cls(styles["banner-text"], "pr-3")}>
            We’ve noticed you’re visiting from Canada. For the best experience
            tailored to your location, please visit our Canadian website.
          </div>
          <KButton
            className="mr-3"
            variant="black"
            size="small"
            onClick={() => (window.location.href = "https://www.kikoff.ca")}
          >
            Take me there!
          </KButton>
        </div>
      )}
    </div>
  );
}
