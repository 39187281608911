import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import useErrorConsumer from "@kikoff/hooks/src/useErrorConsumer";
import { combineClasses } from "@kikoff/utils/src/string";

import CheckAnimation from "../animations/CheckAnimation";
import Delay from "../animations/Delay";
import Expand from "../animations/Expand";
import GenericSpinner from "../animations/GenericSpinner";

import styles from "./LoadableButton.module.scss";

const CANCEL = Symbol("LOADABLE_BUTTON_CANCEL");

namespace LoadableButton {
  export type Status = "initial" | "pending" | "success";
  export type Props = Omit<JSX.IntrinsicElements["button"], "onClick"> & {
    className?: string;
    status?: Status;
    text?: {
      pending?: string;
      success?: string;
    };
    onClick?(
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      context: { resetStatus(): void; cancel(): typeof CANCEL }
    ): Promise<any> | void;
    children?: React.ReactNode;
  };
}

export default LoadableButton;
function LoadableButton({
  className,
  status,
  text: { pending = "Loading...", success = "Success" } = {},
  onClick,
  children,
  disabled,
  ...props
}: LoadableButton.Props) {
  const controlled = !!status;

  const error = useErrorConsumer();

  const [internalStatus, setInternalStatus] = useState<LoadableButton.Status>(
    "initial"
  );

  status ??= internalStatus;

  return (
    <button
      type="button"
      className={combineClasses(
        styles["loadable-button"],
        "text:regular",
        className
      )}
      disabled={status !== "initial" || disabled}
      onClick={
        onClick &&
        ((e) => {
          (async () => {
            const res = onClick(e, {
              resetStatus() {
                Promise.resolve().then(() => {
                  setInternalStatus("initial");
                });
              },
              cancel() {
                return CANCEL;
              },
            });
            if (controlled) return;
            setInternalStatus("pending");
            const result = await res;
            if (result === CANCEL) {
              setInternalStatus("initial");
              return;
            }
            setInternalStatus("success");
          })().catch(() => {
            setInternalStatus("initial");
          });
        })
      }
      {...props}
    >
      <div className={styles.content}>{children}</div>
      <AnimatePresence>
        {status === "pending" && (
          <motion.div
            key="pending"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`${styles.cover} ${styles.pending}`}
          >
            <GenericSpinner /> <span className={styles.text}>{pending}</span>
          </motion.div>
        )}
        {status === "success" && (
          <motion.div
            key="success"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`${styles.cover} ${styles.success}`}
          >
            <Delay for={300}>
              <CheckAnimation />
              <Delay for={300}>
                <Expand>
                  <div className={styles.text}>{success}</div>
                </Expand>
              </Delay>
            </Delay>
          </motion.div>
        )}
      </AnimatePresence>
    </button>
  );
}

LoadableButton.successDuration = 1500;
LoadableButton.afterSuccess = (fn: () => void) => {
  setTimeout(fn, LoadableButton.successDuration);
};
