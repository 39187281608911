import { useEffect, useState } from "react";
import Cookie from "js-cookie";

import { getScript } from "@kikoff/client-utils/src/dom";
import { InteractiveError } from "@kikoff/hooks/src/useError";
import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { KIKOFF_GOOGLE_AUTH_CLIENT_ID } from "@kikoff/utils/src/env";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";
import { cls } from "@kikoff/utils/src/string";

import { useExperiment } from "@src/experiments/context";
import { track } from "@util/analytics";

import styles from "./GoogleSignInButton.module.scss";

declare global {
  namespace google.accounts.id {
    interface GsiButtonConfiguration {
      nonce?: string;
    }
  }
}

interface GoogleSignInButtonProps {
  csrfToken: string;
  requestType: web.public_.CallbackRequest.RequestType;
  onStateChange(
    state: "initial" | "pending" | "created" | "authenticated"
  ): void;
  disabled?: boolean;
  error: InteractiveError;
  onClickWhenDisabled?: (show: boolean) => void;
}

export default function GoogleSignInButton({
  csrfToken,
  requestType,
  onStateChange,
  disabled,
  error,
  onClickWhenDisabled,
}: GoogleSignInButtonProps) {
  const ssoButtonAlwaysClickable =
    onClickWhenDisabled &&
    useExperiment("googleSsoAlwaysClickable").variant === "alwaysClickable";

  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (!scriptLoaded) {
      getScript("https://accounts.google.com/gsi/client").then(() => {
        setScriptLoaded(true);
        initializeGoogleSignIn();
      });
    } else {
      initializeGoogleSignIn();
    }
  }, [scriptLoaded, disabled]);

  const initializeGoogleSignIn = () => {
    if (window.google?.accounts) {
      google.accounts.id.initialize({
        client_id: KIKOFF_GOOGLE_AUTH_CLIENT_ID,
        callback: (response) => {
          if (disabled) return;

          track.tap("Google Sso - Button");
          onStateChange("pending");

          webRPC.GoogleSSO.callback({
            jwt: response.credential,
            csrfToken,
            referrerToken: Cookie.get("refer"),
            origin: web.public_.CallbackRequest.RequestOrigin.WEB,
            requestType,
          })
            .then(
              handleProtoStatus({
                CREATED() {
                  track("Google Sso - Created User");
                  onStateChange("created");
                },
                AUTHENTICATED() {
                  track("Google Sso - Authenticated User");
                  onStateChange("authenticated");
                },
                _DEFAULT: handleFailedStatus(),
              })
            )
            .catch((err) => {
              track("Google Sso - Failed Credentials");
              onStateChange("initial");
              error.throw(err.message);
            });
        },
      });

      google.accounts.id.renderButton(document.getElementById("googleSSODiv"), {
        theme: "outline",
        size: "large",
        shape: "pill",
        type: "standard",
        text: "continue_with",
        nonce: csrfToken,
      });
    }
  };

  return (
    <div
      onClick={() => {
        if (disabled && ssoButtonAlwaysClickable) {
          onClickWhenDisabled(true);
        }
      }}
      className={cls(
        styles["google-sso-container"],
        ssoButtonAlwaysClickable ? styles["cursor-pointer"] : ""
      )}
    >
      <div
        id="googleSSODiv"
        className={
          disabled
            ? ssoButtonAlwaysClickable
              ? styles["pointer-events-none"]
              : styles["disabled"]
            : ""
        }
      />
    </div>
  );
}
